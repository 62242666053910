
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth, useLoginWithRedirect } from '@frontegg/react';
import { Lock } from 'lucide-react';

export default function Login() {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const loginWithRedirect = useLoginWithRedirect();

  React.useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      const nonce = params.get('nonce');
      
      if (code && nonce && isAuthenticated) {
        navigate('/demo', { replace: true });
      }
    };

    handleCallback();
  }, [isAuthenticated, navigate]);

  React.useEffect(() => {
    const handleAuth = async () => {
      if (user?.id) {
        await new Promise(resolve => setTimeout(resolve, 100));
        navigate('/demo', { replace: true });
      }
    };
    handleAuth();
  }, [user, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-gray-900 via-gray-800 to-gray-900">
      <div className="max-w-md w-full space-y-6 p-8 bg-white/5 backdrop-blur-lg rounded-2xl shadow-2xl animate-fade-in">
        <div className="flex flex-col items-center">
          <img 
            src="https://imagedelivery.net/ns1gqzOfNSc1PMvLiRri6w/0c35d9d5-a856-41ce-1133-2163aa3b1d00/public"
            alt="Logo"
            className="w-[244px] h-[53px]"
          />
          <h2 className="mt-6 text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-emerald-500">
            Expert Path
          </h2>
          <p className="mt-2 text-gray-400">
            Advanced diagnostics for IT professionals
          </p>
          <button
            onClick={() => loginWithRedirect()}
            className="mt-8 w-full group relative px-6 py-3 text-white font-medium rounded-lg bg-gradient-to-r from-blue-500 to-emerald-500 hover:opacity-90 overflow-hidden flex items-center justify-center"
          >
            <Lock className="h-4 w-4 mr-2" />
            <span className="relative z-10">Single Sign-On</span>
          </button>
        </div>
      </div>
    </div>
  );
}
