import React from 'react';
import Layout from './components/Layout';
import Login from './components/Login';
import { SettingsProvider } from './context/SettingsContext';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAuth, ContextHolder } from '@frontegg/react';

function App() {
  const { isAuthenticated } = useAuth();
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (ContextHolder.getContext()) {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <SettingsProvider>
      <Routes>
        <Route path="/" element={<Navigate to="/demo" replace />} />
        <Route path="/oauth/callback" element={<Navigate to="/demo" />} />
        <Route path="/*" element={<Layout />} />
      </Routes>
    </SettingsProvider>
  );
}

export default App;