
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { FronteggProvider } from '@frontegg/react';
import App from './App';
import './index.css';

const contextOptions = {
  baseUrl: 'https://app-6yutcyqk6ant.us.frontegg.com',
  clientId: 'af20fef1-c350-4b74-ac85-af1538eb4c38',
  appId: '3dcf4477-587a-40fe-a1ba-a871f5304453',
  cookieDomain: window.location.hostname,
  requestCredentials: 'include',
  storageLocation: localStorage,
  singleTenant: true,
  singletonMode: true
};

const authOptions = {
  keepSessionAlive: true,
  redirect_uri: `${window.location.origin}/oauth/callback`,
  refreshToken: true,
  refreshTokenInterval: 60000,
  tokenRefreshThresholdMs: 300000,
  storagePrefix: 'fe_',
  cookiePolicy: 'single_host_origin',
  cookieDomain: window.location.hostname,
  stateHandler: {
    async read() {
      return sessionStorage.getItem('frontegg_state');
    },
    async write(state) {
      sessionStorage.setItem('frontegg_state', state);
    },
    async remove() {
      sessionStorage.removeItem('frontegg_state');
    }
  }
};

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <BrowserRouter>
      <FronteggProvider 
        contextOptions={contextOptions} 
        hostedLoginBox={true}
        authOptions={authOptions}>
        <App />
      </FronteggProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
