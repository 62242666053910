import React from 'react';
import { Question } from '../../types/expert';
import { clsx } from 'clsx';

interface QuestionRendererProps {
  question: Question;
  onAnswer: (answer: string) => void;
  isProcessing: boolean;
}

export default function QuestionRenderer({ 
  question,
  onAnswer,
  isProcessing
}: QuestionRendererProps) {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const answer = formData.get('answer') as string;
    if (answer?.trim()) {
      onAnswer(answer.trim());
      (e.target as HTMLFormElement).reset();
    }
  };

  return (
    <div className="space-y-4">
      <span className="text-gray-700 text-lg">{question.text}</span>

      <form onSubmit={handleSubmit} className="space-y-4">
        {question.type === 'dropdown' && (
          <select
            name="answer"
            className="mt-2 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={isProcessing}
          >
            <option value="">Select an option...</option>
            {question.options?.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
        {question.type === 'text' && (
          <input
            type="text"
            name="answer"
            className="mt-2 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={isProcessing}
          />
        )}
        {question.type === 'multiline' && (
          <textarea
            name="answer"
            rows={4}
            className="mt-2 block w-full rounded-lg border border-gray-300 px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            disabled={isProcessing}
          />
        )}
        <div className="flex justify-between items-center">
          <a 
            href="https://learn.microsoft.com/en-us/windows/client-management/troubleshoot-windows-boot-problems" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            Docs
          </a>
          <button
            type="submit"
            disabled={isProcessing}
            className={clsx(
              'px-4 py-2 text-white rounded-lg disabled:opacity-50',
              isProcessing ? 'bg-gray-500' : 'bg-blue-600 hover:bg-blue-700'
            )}
          >
            {isProcessing ? 'Processing...' : 'Submit'}
          </button>
        </div>
      </form>
    </div>
  );
}