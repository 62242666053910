import React from 'react';
import { NavLink } from 'react-router-dom';
import { Book, Boxes, Search, Laptop, LayoutDashboard, Settings, Bot } from 'lucide-react';
import { clsx } from 'clsx';

const navigation = [
  { name: 'Dashboard', icon: LayoutDashboard, href: '/' },
  { name: 'Demo', icon: Bot, href: '/demo' },
  { name: 'Playbook', icon: Book, href: '/playbook' },
  { name: 'Modules', icon: Boxes, href: '/modules' },
  { name: 'Settings', icon: Settings, href: '/settings' },
];

export default function Sidebar() {
  return (
    <div className="flex flex-col w-64 h-full bg-gray-900">
      <div className="flex items-center justify-between h-16 px-4 bg-gray-800">
        <div className="flex items-center">
          <Laptop className="h-8 w-8 text-blue-500" />
          <span className="ml-2 text-xl font-semibold text-white">FixFinder</span>
        </div>
      </div>
      <nav className="flex-1 px-2 py-4 space-y-1 overflow-y-auto">
        {navigation.map((item) => (
          <NavLink
            key={item.name}
            to={item.href}
            className={({ isActive }) =>
              clsx(
                'flex items-center px-4 py-2 text-sm font-medium rounded-lg',
                isActive
                  ? 'bg-gray-800 text-white'
                  : 'text-gray-300 hover:bg-gray-800 hover:text-white'
              )
            }
          >
            <item.icon className="h-5 w-5 mr-3" />
            {item.name}
          </NavLink>
        ))}
      </nav>
    </div>
  );
}